import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavComp from './components/Nav';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import Truck from './components/Truck';
import Cards from './components/Cards';
import Pvideo from './components/Pvideo';
import Review from './components/Review';
import Preguntas from './components/Faqs';
import Footer from './components/Footer';
import Terms from './components/Terms';
import Gracias from './components/Gracias';

function App() {
  return (
    <Router>
    <div className="App">
        <NavComp/>
        <Routes>
        <Route path="/" element={<>
            <Home/>
            <Truck/>
            <Cards/>
            <Pvideo/>
            <Review/>
            <Preguntas/>
            </>} />
            <Route path='/terms'element={<Terms/>}/>
            <Route path='/home'element={<Home/>}/>
            <Route path='/gracias' element={<Gracias/>}/>
      </Routes>
      </div>
      <Footer/>
      </Router>
  );
}

export default App;
