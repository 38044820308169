import React, { useState, useEffect } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import TopBanner from "./Banner";

const NavComp = () => {
  const [expanded, setExpanded] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleNavLinkClick = (path, hash) => {
    navigate(path);
    setTimeout(() => {
      const element = document.getElementById(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 100); // Delay to ensure navigation has completed
  };

  return (
    <>
      <TopBanner />
      <Navbar
        style={{ backgroundColor: "#000321", zIndex: "100", paddingLeft: '6rem', paddingRight: '6rem' }}
        variant="dark"
        expand="lg"
        className={`custom-navbar ${isSticky ? "sticky" : ""}`}
        expanded={expanded}
      >
        <Container fluid>
          <div className="d-flex justify-content-between align-items-center w-100">
            <Navbar.Brand href="/" className="">
              <img
                src="images/logo.png"
                width="187px"
                height="70px"
                className="d-inline-block bd-highlight"
                alt="Logo"
              />
            </Navbar.Brand>
            <div className="d-flex align-items-center">
              <Navbar.Toggle
                className="ms-5"
                aria-controls="basic-navbar-nav"
                onClick={() => setExpanded(expanded ? false : "expanded")}
              />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav>
                  <Nav.Link
                    onClick={() => {
                      setExpanded(false);
                      handleNavLinkClick("/", "quienes");
                    }}
                    className="nav-link-custom"
                  >
                    Quienes Somos
                  </Nav.Link>
                  <Nav.Link
                    onClick={() => {
                      setExpanded(false);
                      handleNavLinkClick("/", "preguntas");
                    }}
                    className="nav-link-custom"
                  >
                    Preguntas Frecuentes
                  </Nav.Link>
                  <Nav.Link
                    onClick={() => {
                      setExpanded(false);
                      handleNavLinkClick("/terms", "top"); // Assuming you want to scroll to the top on the terms page
                    }}
                    className="nav-link-custom"
                  >
                    Términos y Condiciones
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </div>
          </div>
        </Container>
      </Navbar>
    </>
  );
};

export default NavComp;
